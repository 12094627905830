.symbolNegative {
    color: #ff0000c2;
    font-weight: bold;
}

.symbolPositive {
    color: #428c52;
    font-weight: bold;
}

.futureTransaction {
    background-color: #ededed;
}
