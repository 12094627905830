.App-header {
    background-color: #282c34;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 24px;
}

.logo {
    width: 160px;
}

.headerTitle {
    margin-bottom: 24px;
}
